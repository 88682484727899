<template>
  <div id="login-container">
    <div class="login-card">
      <div class="form-container">
        <div class="header">بازیابی رمز عبور</div>
        <img class="liner" src="../../assets/images/Line-login.svg" alt="" />
        <form class="login-form" @submit.prevent>
          <label
            class="input-label"
            :class="{ error: this.$store.state.status === 'error' }"
            for=""
          >
            <input
              v-model="code"
              type="text"
              name=""
              id=""
              placeholder="کد پنج رقمی"
              @keypress="isNumber($event)"
              @keyup="checkLength($event)"
            />
            <span v-if="timer" class="timer">{{
              secondsToMinutes(timerCount)
            }}</span>
            <span v-if="tryCodeAgain" @click="tryCode" class="try-code">
              ارسال مجدد کد
            </span>
          </label>
          <div
            v-if="this.$store.state.status === 'error'"
            class="error-massage"
          >
            {{ this.$store.state.message }}
          </div>
          <button @click="recoverPassword" class="btn-singup">
            بازیابی رمز عبور
          </button>
          <button @click="login" class="btn-register">ورود</button>
        </form>
      </div>
      <img
        class="login-image"
        src="../../assets/images/auth-image.svg"
        height="502"
      />
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      code: "",
      timerCount: 5,
      timer: true,
      tryCodeAgain: false,
      color: "#ffffff",
      size: "40px"
    };
  },
  methods: {
    recoverPassword() {
      if (!this.code) {
        let message = "کد را وارد کنید";
        this.$store.dispatch("validationError", { message });
        return false;
      }
      if (this.code.length < 5) {
        let message = "کد وارد شده صحیح نمیباشد";
        this.$store.dispatch("validationError", { message });
        return false;
      }
      let code = this.code;
      this.$store
        .dispatch("forgetPasswordCodeVerification", { code })
        .then(() => {
          this.$router.push("/recover-password");
        })
        .catch(error => console.log(error));
    },
    login() {
      this.$router.push("/login");
    },
    register() {
      this.$router.push("/register");
    },
    tryCode() {
      //
    },
    secondsToMinutes(s) {
      let min = Math.floor(s / 60);
      let sec = Math.floor(s % 60);
      return min + " : " + sec;
    },
    isNumber: function(evt) {
      evt = evt ? evt : window.event;
      let charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      }
      if (evt.target.value.length > 4) {
        evt.preventDefault();
      }
      return true;
    },
    checkLength: function(evt) {
      let event = evt;
      if (event.target.value.length > 5) {
        event.preventDefault();
      }
    }
  },
  watch: {
    timerCount: {
      handler(value) {
        if (value > 0) {
          setTimeout(() => {
            this.timerCount--;
          }, 1000);
        }
        if (value === 0) {
          this.timer = false;
          this.tryCodeAgain = true;
        }
      },
      immediate: true // This ensures the watcher is triggered upon creation
    }
  }
};
</script>
<style scoped>
::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: rgb(248, 241, 241);
  opacity: 1;
}

#login-container {
  width: 100%;
  height: 100vh;
  background: linear-gradient(75.38deg, #5c6699 0%, #070d59 100%);
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-card {
  background: #1f3c88;
  border-radius: 20px;
  width: 1052px;
  height: 545px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  /*margin: 0px 160px;*/
}

.login-image {
  position: absolute;
  left: -14%;
  top: -18%;
  width: 70%;
}

.form-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin: 6%;
  width: 88%;
  max-width: 400px;
}

.header {
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 44px;
  color: #fff;
  margin-bottom: 10px;
}

.login-form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.input-label {
  display: flex;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 5px;
  width: 350px;
  height: 30px;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 12px;
}

.input-label input {
  background-color: transparent;
  border: none;
  height: 100%;
  outline: none;
  width: calc(100% - 35px);
  padding-right: 10px;
  color: #ffff;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
}

.input-label img {
  height: 24px;
  padding-right: 10px;
}

.liner {
  margin-bottom: 6px;
}

.btn-singup {
  font-family: "Vazir Medium FD";
  border: none;
  background: #ffffff;
  border-radius: 10px;
  width: 285px;
  height: 40px;
  margin-top: 28px;
  margin-bottom: 6px;
  font-weight: 500;
  font-size: 22px;
  line-height: 34px;
  color: #070d59;
  cursor: pointer;
}

.btn-register {
  font-family: "Vazir Medium FD";
  margin-top: 5px;
  margin-bottom: 5px;
  border: none;
  width: 285px;
  height: 40px;
  background: #1f3c88;
  border: 1px solid #ffffff;
  border-radius: 10px;
  color: #fff;
  font-weight: 500;
  font-size: 22px;
  line-height: 34px;
  cursor: pointer;
}

.timer {
  padding-left: 10px;
  color: #ffffff;
  opacity: 0.6;
  width: 35%;
  direction: ltr;
  text-align: left;
}

.try-code {
  /* position: relative; */
  /* margin-top: -15px; */
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #fff;
  opacity: 0.6;
  text-align: left;
  cursor: pointer;
  /* align-self: flex-end; */
  /* margin-left: 25px; */
  width: 54%;
  padding-left: 10px;
}

.error {
  border: 1px solid #ff6b6b;
}

.error-massage {
  margin-top: -10px;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #ff6b6b;
  text-align: right;
  cursor: pointer;
  align-self: flex-start;
}

/* responseiv section */
@media only screen and (max-width: 320px) {
  .form-container {
    min-width: 100%;
    margin: 0 auto;
  }

  .login-card {
    width: 100%;
    margin: auto;
  }

  .form-container {
    min-width: 100%;
    margin: 0 auto;
  }

  .login-image {
    display: none;
  }

  .header {
    font-size: 1.5em;
  }

  .liner {
    width: 100%;
  }

  .input-label {
    width: 100%;
  }

  .input-label-error {
    width: 80%;
  }

  .input-label input {
    font-size: 0.7em;
  }

  .input-label-error input {
    font-size: 0.7em;
  }

  .input-label img {
    width: 30px;
  }

  .btn-singup {
    width: 100%;
    font-size: 16px;
  }

  .btn-register {
    width: 100%;
    font-size: 16px;
  }

  .text-information {
    margin-right: 60px;
    font-size: 0.5em;
  }

  .error-massage {
    font-size: 0.5em;
  }

  .forget-password {
    font-size: 0.5em;
  }
}

@media (min-width: 321px) and (max-width: 480px) {
  #login-container {
  }

  .form-container {
    margin: 0 auto;
  }

  .login-card {
    width: 100%;
  }

  .text-information {
    margin-right: 60px;
    font-size: 0.5em;
  }

  .header {
    font-size: 1.5em;
  }

  .input-label {
    width: 100%;
  }

  .input-label input {
    font-size: 0.7em;
  }

  .input-label-error {
  }

  .input-label-error input {
    font-size: 0.7em;
  }

  .input-label img {
    width: 30px;
  }

  .error-massage {
    font-size: 0.5em;
  }

  .forget-password {
    font-size: 0.5em;
  }

  .liner {
    width: 100%;
  }

  .btn-singup {
    width: 80%;
    font-size: 1em;
  }

  .btn-register {
    width: 80%;
    font-size: 1em;
  }
}

@media only screen and (max-width: 1140px) {
  .login-image {
    left: -8%;
    top: -13%;
    width: 65%;
  }

  #login-container {
    padding: 0 70px;
  }
}

@media only screen and (max-width: 960px) {
  .login-image {
    left: -11%;
    width: 60%;
    top: -8%;
  }

  #login-container {
    padding: 0 10px;
  }

  .login-card {
    margin-top: 70px;
  }
}

@media only screen and (max-width: 768px) {
  .login-image {
    display: none;
  }

  .login-form {
    width: 80%;
  }

  .login-card {
    margin-top: 70px;
  }
}
</style>
